
/*font @import*/
@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100..700&display=swap');
/*font-family: "Josefin Sans", sans-serif;*/


@import url('https://fonts.googleapis.com/css2?family=Maname&display=swap');
/* font-family: "Maname", serif;*/

@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');
/*font-family: "Zain", sans-serif;*/


@import url('https://fonts.googleapis.com/css2?family=Stylish&display=swap');
/*font-family: "Stylish", serif;*/


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*font-family: "Poppins", sans-serif;*/

@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');
/*font-family: "Gupter", serif;*/

:root{
  --ff1 : "Josefin Sans", sans-serif;
  --ff2 : "Maname", serif;
  --ff3 : "Zain", sans-serif;
  --ff4 : "Stylish", serif;
  --ff5 : "Poppins", sans-serif;
  --ff6 : "Gupter", serif;
}

body::-webkit-scrollbar{
  display: none;
}

.Home-cnt-01{
  width: 99%;
  height: 90vh;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  background: linear-gradient(90deg, rgb(54, 174, 253), rgb(34, 84, 250));
}

@media all and (max-width : 700px){
  .Home-cnt-01{
    width: 95%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}

.Home-cnt-01-sub-01{
  line-height: 90px;
  font-size: 30px;
  color: rgb(0, 0, 0);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  animation-duration: 2s;
  animation-name: slide1;
}


.Home-cnt-01-sub-01 span{
  color: rgb(255, 62, 4);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Home-cnt-01-sub-01 hr{
  position: relative;
  top: -40px;
  width: 200px;
  color: rgba(240, 248, 255, 0.829);
}

.Home-cnt-01-sub-02{
  width: 90%;
  height: auto;
  margin: 10px;
  display: flex;
  position: relative;
  justify-content: center;
  gap: 150px;
}


.Home-cnt-01-sub-02-sub-01{
  width: 40%;
  height: 150px;
  align-content: center;
  text-align: start;
}

.Home-cnt-01-sub-02-sub-02{
  width: 20%;
  height: auto;
  /* background-color: aliceblue; */
  overflow: hidden;
  border-radius: 5px;
  animation-duration: 1.5s;
  animation-name: slide2;
}

@keyframes slide2 {
  from {
    translate: 150vh 0;
    scale: 200% 1.2;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}


@keyframes slide1 {
  from {
    translate: 0 -250vw;
    scale: 200% 2;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}



.Home-cnt-01-sub-02-sub-02 img{
  width: 100%;
  height: auto;
  object-fit: fill;
}

.Home-cnt-01-sub-02-sub-01-h2-02{
  text-align: start;
  color: antiquewhite;
  font-family: cursive;
}

@media all and (max-width : 1000px){
  .Home-cnt-01-sub-02-sub-02{
    display: none;
  }
  .Home-cnt-01-sub-02-sub-02{
    width: 100%;
  }
  .Home-cnt-01-sub-02-sub-01{
    width: 100%;
    text-align: center;
  }
  .Home-cnt-01-sub-02-sub-01-h2-02{
    text-align: center;
  }
  .Home-cnt-01{
    height: 60vh;
  }
}

.Home-cnt-01-sub-02-sub-01-h2-01{
  font-family: var(--ff1);
  font-weight: 300;
  font-size: 25px;
  color: white;
  animation-duration: 1.5s;
  animation-name: slide5;
}

@keyframes slide5 {
  from {
    translate: -150vh 0;
    scale: 200% 2;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}


.Home-cnt-01-sub-02-sub-01-h2-01-span-01{
  font-family: var(--ff6);
  color: aliceblue;
  text-align: start;
  color: #2dfc04;
  font-weight: 700;
}

.Home-cnt-01-sub-02-sub-01-h2-01-span-02{
  color: rgb(255, 255, 255);
  font-weight: 400;
}



.Home-cnt-01-sub-02-sub-03{
  text-align: center;
  width: 200px;
}

.Home-cnt-01-sub-02-sub-01-sub-cnt-01 button{
  width: 120px;
  height: 35px;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 2px 2px 10px gray;
  color: white;
  background: linear-gradient(90deg, orange, orangered);
  box-shadow: 2px 5px 10px rgb(236, 236, 236);
  font-family: var(--ff1);
  font-size: 15px;
  cursor: pointer;
  animation-duration: 0.3s;
  animation-name: slide4;
}

@keyframes slide4 {
  from {
    translate: -150vh 0;
    scale: 200% 2;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}


.Home-cnt-01-sub-02-sub-01-sub-cnt-02{
  font-family: "Josefin Sans", sans-serif;
  color: rgb(255, 255, 255);
  position: relative;
  top: 15px;
  letter-spacing: 1px;
  font-size: 25px;
  animation-duration: 1s;
  animation-name: slide3;
}

@keyframes slide3 {
  from {
    translate: -150vh 0;
    scale: 200% 2;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}


@media all and (max-width : 600px ){
  .Home-cnt-01-sub-01{
    font-size: 30px;
  }
  .Home-cnt-01-sub-02-sub-01-h2-02{
    font-size: 26px;
  }
  .Home-cnt-01-sub-02-sub-01-sub-cnt-02{
    font-size: 15px;
  }
  .Home-cnt-01-sub-02-sub-01-sub-cnt-01{
    font-size: 15px;
  }
  .Home-cnt-01{
    height: 50vh;
  }
  .Home-cnt-01-sub-02-sub-01-sub-cnt-01 h3{
    font-size: 10px;
  }
}

.Home-cnt-02{
  width: 95%;
  height: auto;
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

@media all and (max-width : 700px){
  .Home-cnt-02{
    margin-top: -80px;
  }
}

.Home-cnt-02-sub-01{
  width: 350px;
  height: 300px;
  border: 1px solid gray;
  margin: 10px;
  background: linear-gradient(120deg, rgb(252, 252, 252), rgb(36, 194, 247));
  border-radius: 10px;
  box-shadow: 10px 10px 20px gray;
  opacity: 0;
  transform: scale(0.4);
  transition: opacity 0.5s, transform 0.5s;
  animation-duration: 1.5s;
  animation-name: slide2;

  /* animation-range: entry 0; */
}

.Home-cnt-02-sub-01.show {
  opacity: 1;
  transform: scale(1);
}

.Home-cnt-02-sub-01.hide {
  opacity: 0;
  transform: scale(0.4);
}

.Home-cnt-02-sub-01-sub-01{
  width: 90%;
  height: 50%;
  margin-top: 20px;
  overflow: hidden;
}

.Home-cnt-02-sub-01-sub-01 img{
  width: 100%;
  height: auto;
  object-fit: fill;
}

.Home-cnt-02-sub-01-sub-02{
  width: 90%;
  height: 30%;
  margin-top: 10px;
  align-content: center;
  color: rgb(78, 78, 78);
}

.Home-cnt-02-sub-01-sub-02 strong{
  font-family: "Edu AU VIC WA NT Hand", cursive;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
}

.signup-input-01{
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid;
  text-align: center;
  align-content: center;
  /* box-shadow: 2px 2px 10px gray; */
  overflow: hidden;
  display: flex;
}

.signup-input-01 div{
  align-content: center;
}

.signup-submit-btn-01{
  width: 120px;
  height: 30px;
  background-color: rgb(255, 83, 15);
  border: none;
  border-radius: 5px;
  /* box-shadow: 10px 10px 20px gray; */
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

.signup-input-01 input{
  border: none;
  text-align: center;
}



.signup-form-cnt-01{
  width: 300px;
  height: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 10px 10px 20px gray;
  background-color: aliceblue;
}

.signup-h2-main-cnt-01 h2{
  font-family: "Zain", sans-serif;
  font-weight: 600;
  color: rgb(59, 59, 59);
  text-shadow: 2px 2px 10px rgb(168, 168, 168);
}



.signup-h2-main-cnt-01 h2 span{
  color: rgb(0, 153, 255);
}

@media all and (min-width : 700px){
  .signup-h2-main-cnt-01 h2{
    font-size: 30px;
  }
}

@media all and (max-width : 700px){
  .signup-h2-main-cnt-01 h2{
    font-size: 25px;
  }
}

.signup-form-cnt-01-sub-cnt-01{
  position: relative;
  align-content: center;
}

.signup-input-02{
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid;
  text-align: center;
  /* box-shadow: 2px 2px 10px gray; */
}

.signup-form-cnt-01-sub-cnt-01 div{
  position: absolute;
  top: 5px;
  right: 5%;
}

@media all and (max-width : 450px){
  .signup-form-cnt-01{
    width: 80%;
  }
  .signup-input-01{
    width: 70%;
  }
  .signup-input-02{
    width: 70%;
  }
  .signup-form-cnt-01-sub-cnt-01{
    width: 100%;
  }
}

.signup-h2-main-cnt-02{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px;
}

.signup-login-text-01{
  color: rgb(0, 0, 0);
  font-family: "Stylish" ,serif;
  font-weight: 600;
  cursor: pointer;
  text-shadow: 2px 2px 10px gray;
  font-size: 13px;
}

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
.four_zero_four_bg h3{
      font-size:80px;
}
			 
.link_404{			 
	color: #fff!important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid;
  border-radius: 20px;
  width: 120px;
  height: 40px;
  cursor: pointer;
}
.contant_box_404{ 
  margin-top:-50px;
}

.app-navi-main-cnt{
  position: absolute;
  top: 70px;
  right: 100px;
  cursor: pointer;
}

.app-navi-main-cnt-span-icon{
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

@media all and (max-width : 700px){
  .app-navi-main-cnt{
    right: 10%;
  }
}


.account-main-cnt-01{
  /* width: 90%;
  min-height: 100px;
  border: 1px solid; */
}

.account-main-cnt-01-span-01{
  font-family: "Stylish", serif;
  font-size: 5vw;
}

@media all and (max-width : 700px){
  .account-main-cnt-01-span-01{
    font-size: 8vw;
  }
}

.account-main-cnt-01-sub-01{
  width: 90%;
  height: auto;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 10px;
  align-content: center;
  padding-top: 10px;
  color: #fff;
  background:linear-gradient(90deg, rgb(40, 187, 255), rgb(0, 103, 221));
  box-shadow: 3px 5px 10px gray;
}

.account-main-cnt-01-sub-01{
  font-size: 20px;
}

@media all and (max-width : 700px){
  .account-main-cnt-01-sub-01{
    font-size: 16px;
  }
}

.account-main-cnt-01-sub-01-sub-span-01{
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.account-main-cnt-01-sub-01-sub-span-01 span{
  color: #11ff00;
  font-weight: 1000;
}

.account-main-cnt-01-sub-01-sub-h2-icon-01{
  font-size: 150%;
  color: #39ac31;
  border-radius: 50%;
  box-shadow: 2px 2px 8px rgb(82, 80, 80);
  cursor: pointer;
  background-color: #ffffff;
}

.account-main-cnt-02{
  width: 90%;
  height: auto;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.account-main-cnt-02-sub-cnt-01{
  width: 29%;
  min-height: 80px;
  max-height: auto;
  border: 1px solid gray;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 3px 5px 10px rgb(107, 107, 107);
  background: linear-gradient(120deg, rgba(43, 160, 255, 0.815), rgb(200, 244, 255));
  align-content: center;
  cursor: pointer;
}

.account-main-cnt-02-sub-cnt-01-sub-icon-01{
  font-size: 25px;
  color: #555555;
}

.account-main-cnt-02-sub-cnt-01-span-01{
  font-size: 18px;
  font-family: "Gupter", serif;
  font-weight: 800;
  color: black;
}

@media all and (max-width : 700px){
  .account-main-cnt-02-sub-cnt-01-span-01{
    font-size: 15px;
  }
}

.account-subb-part-01{
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 25px;
}

.ac_upi-main-cnt-01{
  width: 60%;
  height:auto;
  border: 1px solid;
  border-radius: 10px;
  align-content: center;
  padding-top: 10px;
  background-color: aliceblue;
}

@media all and (max-width : 700px){
  .ac_upi-main-cnt-01{
    width: 90%;
  }
}

.ac_upi-main-cnt-01 input, select{
  margin: 5px;
  width: 200px;
  height: 30px;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
}

.ac_upi-main-cnt-01 button{
  width: 120px;
  height: 35px;
  margin: 10px;
  color: white;
  background: linear-gradient(90deg, rgb(69, 184, 250), rgb(1, 108, 247));
  border: 0.1px solid gray;
  border-radius: 10px;
  box-shadow: 2px 2px 10px gray;
  cursor: pointer;
}

.account-subb-part-strong-01{
  font-size: 14px;
  color: rgb(2, 158, 10);
  font-family: "Gupter", serif;
  margin: 10px;
}

.ac_upi-main-cnt-02{
  width: 90%;
  height: auto;
  border: 1px solid;
  border-radius: 10px;
  align-content: center;
  background: linear-gradient(120deg, rgba(23, 177, 248, 0.61), rgb(0, 132, 255));
}

.ac_upi-main-cnt-02-sub-01{
  align-content: center;
  padding: 20px;
}



.ac_upi-main-cnt-02-sub-01 strong{
  font-size: 19px;
  font-family: "Gupter", serif;
  color: rgb(255, 255, 255);
  font-weight: 100;
}

.ac_upi-main-cnt-02-sub-01 strong span{
  color: #131313;
  font-weight: 500;
}

@media all and (max-width : 700px){
  .ac_upi-main-cnt-02-sub-01 strong{
    font-size: 18px;
  }
}

.ac_upi-main-cnt-02-sub-02 input{
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid;
  margin: 5px;
  text-align: center;
}

.ac_upi-main-cnt-02-sub-02 button{
 width: 120px;
 height: 35px;
 margin: 10px;
 border: 0.1px solid gray;
 border-radius: 8px;
 background: linear-gradient(90deg, rgb(255, 136, 0), rgb(248, 70, 0)); 
 color: #fff;
 letter-spacing: 1px;
 cursor: pointer;
}

.ac_upi-main-cnt-03{
  width: 500px;
  height: auto;
  background-color: #fff;
  border: 1px solid;
  box-shadow: 2px 2px 10px gray;
  border-radius: 10px;
  padding-top: 20px;
  align-content: center;
  padding-bottom: 10px;
}

@media all and (max-width : 700px){
  .ac_upi-main-cnt-03{
    width: 90%;
  }
}


.account_histor-page-main-cnt-01{
  width: 95%;
  height: 65vh;
  border-radius: 5px;
  overflow: scroll;
}

.account_histor-page-main-cnt-01-sub-cnt-01{
  width: 95%;
  height: 50px;
  margin: 2px;
  border-radius: 10px;
  align-content: center;
  border: 0.7px solid;
  background: linear-gradient(140deg, rgb(210, 210, 210), rgb(255, 255, 255));
  /* color: white; */
  cursor: pointer;
}

.account_histor-page-main-cnt-01-sub-cnt-01-span-01{
  font-size: 20px;
  font-family: "Stylish", serif;
}

.account_histor-page-main-cnt-01-sub-cnt-01-span-01 strong{
  color: #0fbe02;
}

.account_histor-page-main-cnt-01-sub-cnt-01-span-02{
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

@media all and (max-width :700px){
  .account_histor-page-main-cnt-01-sub-cnt-01-span-01{
    font-size: 17px;
    
  }
}

.account-pending-main-cnt-01{
  width: 90%;
  height: 40vh;
  border-radius: 5px;
  overflow: scroll;
}

.account-pending-main-cnt-01-sub-cnt-01{
  width: 90%;
  height: 50px;
  border: 1px solid;
  border-radius: 5px;
  margin: 2px;
  align-content: center;
}

.account-pending-main-cnt-01-sub-cnt-01-sub-span-01{
  font-size: 20px;
  font-family: "Stylish", serif;
}

.account-pending-main-cnt-01-sub-cnt-01-sub-span-02{
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
}

@media all and (max-width : 700px){
  .account-pending-main-cnt-01-sub-cnt-01-sub-span-01{
    font-size: 17px;

  }
}

.account-payment-main-cnt-01{
  width: 90%;
  height: auto;
  border: 1px solid gray;
  align-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background: linear-gradient(120deg, rgba(23, 177, 248, 0.61), rgb(0, 132, 255));
  border-top-right-radius: 25px;
  border-bottom-left-radius:10px ;
}

.account-payment-main-cnt-01 span{
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  color: #ffffff;
  margin: 10px;
}


@media all and (max-width : 700px){
  .account-payment-main-cnt-01 span{
    font-size: 15px;
    word-spacing: 1px;
  } 
}

.account-payment-main-cnt-02 span{
  font-size: 18px;
  font-family: 'Courier New', Courier, monospace;
}

.payment-hr-01{
  width: 50%;
  margin: 10px;
}

@media all and (max-width : 700px){
  .payment-hr-01{
    width: 80%;
  }
}

.account-payment-h1-01{
  font-size: 20px;
}

.account-payment-main-cnt-03{
  width: 400px;
  height: auto;
  overflow: hidden;
}

.account-payment-main-cnt-03 img{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

@media all and (max-width :700px){
  .account-payment-main-cnt-03{
    width: 70%;
  }
}
.account-payment-main-cnt-04{
  margin-bottom: 30px;
}

.account-payment-main-cnt-04 input , select{
  width: 200px;
  height: 30px;
  box-shadow: 2px 2px 10px gray;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
}

.account-payment-main-cnt-04 h3{
  font-family: "Stylish";
  font-size: 15px;
}

.account-payment-main-cnt-05{

}

.account-payment-main-cnt-05-sub-icon-01{
  font-size: 30px;
  cursor: pointer;
}

.account-payment-main-cnt-05 strong{
  font-size: 15px;
}

.cart-page-main-h1-01{
  font-size: 30px;
  font-family: "Maname", serif;
}

@media all and (max-width : 700px){
  .cart-page-main-h1-01{
    font-size: 25px;
  }
}

.cart-page-main-cnt-01{
  margin-top: 30px;
  position: relative;
}

.cart-page-main-cnt-01-span-01{
  font-size: 18px;
  font-family: poppins;
  font-weight: 200;
  color: #000000;
  position: absolute;
  left: 20px;
}

.cart-page-main-cnt-01-span-01 span{
  font-weight: 700;
  color: #ff7300;
}
.cart-page-main-cnt-01-span-01-down-hr-01{
  width: 80px;
  position: absolute;
  top: 24px;
  left: 20px;
}

.cart-page-main-cnt-01-span-01-down-hr-02{
  width: 100px;
  position: absolute;
  top: 24px;
  left: 20px;
}


.cart-page-main-cnt-01-sub-cnt-01{
  width: 96%;
  height: auto;
  position: relative;
  top: 30px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  flex-wrap: nowrap;
  gap: 5px;
}

.cart-page-main-cnt-01-sub-cnt-01::-webkit-scrollbar{
  display: none;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01{
  min-width: 250px;
  max-width: 260px;
  min-height: auto;
  border: 1px solid;
  border-top-right-radius: 20px;
  background: linear-gradient(120deg, orange, orangered);
  cursor: pointer;
}

@media all and (max-width : 700px){
  .cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01{
    min-width: 30%;
    max-width: 32%;
  }

  
}

.account_histor-page-main-cnt-01::-webkit-scrollbar{
  display: none;
}

.account-pending-main-cnt-01::-webkit-scrollbar{
  display: none;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01{
 width: 80%;
 height: auto;
 margin-top: 10px;
 overflow: hidden;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01 img{
  width: 100%;
  height: auto;
  object-fit: fill;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01{
  font-size: 20px;
  position: relative;
  top: 5px;
  font-family: "Stylish", sans-serif;
  color: #ffffff;
}

@media all and (max-width : 700px){
  .cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01{
    font-size: 18px;
  }
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01{
  font-size: 15px;
  font-family: popins;
  font-weight: 300;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02{
  font-size: 13px;
  font-family: 'Courier New', Courier, monospace;
}

@media all and (max-width : 700px){
  .cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01{
    font-size: 12px;
  }
  .cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02{
    font-size: 11px;
  }
}

.cart-page-main-cnt-02{
  position: relative;
  margin-top: 50px;
  height: auto;
}

.cart-page-main-cnt-02-sub-cnt-01{
  width: 95%;
  height: auto;
  margin-top: 30px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02{
  height: auto;
  align-content: center;
  margin: 5px;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02 strong{
  font-size: 19px;
  font-family: "Josefin Sans", sans-serif;
}

.cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02 span{
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  color: white;
}

.cart-page-before-history-cnt-01{
  position: absolute;
  width: 50px;
  height: 50px;
  right: 6%;
  top: 20%;
  align-content: center;
}

.cart-page-before-history-cnt-01-icon-01{
  font-size: 20px;
  cursor: pointer;
}

.carthist-cnt-01{
  margin-top: 20px;
}

.carthist-cnt-01 h2{
  font-size: 25px;
  font-family: var(--ff4);
  color: #ff7300;
}

.carthist-cnt-01-sub-cnt-01{
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
  padding: 5px;
}

.setting-page-h1-01{
  font-size: 25px;
  font-family: var(--ff1);
  color: #333131;
}

.setting-cnt-01{
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
  padding: 5px;
}

.setting-cnt-01-sub-01{
  width: 32%;
  height: 100px;
  border: 1px solid gray;
  border-radius: 5px;
  align-content: center;
  background: linear-gradient(120deg, rgb(75, 173, 253), rgba(1, 81, 255, 0.699));
  box-shadow: 2px 2px 10px gray;
  cursor: pointer;
}

.setting-cnt-01-sub-01-span-01{
  font-size: 20px;
  font-family: var(--ff3);
  font-weight: 600;
  color: white;
}

.setting-cnt-01-sub-01-icon-01{
  font-size: 25px;
  color: white;
}


@media all and (max-width : 700px){
  .setting-cnt-01-sub-01-span-01{
    font-size: 17px;
  }
}

.updatepass-h1-01{
  font-family: var(--ff3);
  font-size: 30px;
  font-weight: 600;
}

.updatepass-h1-01 span{
  font-weight: 200;
}

.play-h1-01{
  font-size: 25px;
  font-family: var(--ff3);
  font-weight: 800;
}

.play-h1-01 span{
  font-weight: 300;
}

.play-main-cnt-01{
  width: 300px;
  height: auto;
  box-shadow: 5px 5px 12px rgb(53, 52, 52);
  background-color: #000000;
  cursor: pointer;
  position: relative;
}

.play-main-cnt-01 img{
  width: 100%;
  height: auto;
  object-fit: fill;
}

.play-main-cnt-01 span{
  position: absolute;
  color: white;
  font-size: 20px;
  bottom: 10px;
  left: 0;
  right: 0;
  font-family: var(--ff4);
}

.signup-input-01-span-01{
  font-size: 14px;
  font-family: var(--ff6);
  color: rgb(206, 50, 3);
  font-weight: 600;
}



  /* The Modal (background) */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    font-size: 20px;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    font-family: var(--ff4);
    font-weight: 600;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

.sign-up-load-cnt-01{
  justify-content: center;
  align-content: center;
  height: 50vh;
}

.sign-up-load-cnt-01 div h1{
  font-family: var(--ff2);
  font-size: 20px;
}

.account-main-cnt-01-sub-01 button{
  width: 150px;
  height: 35px;
  color: white;
  background: linear-gradient(90deg, orange, orangered);
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--ff1);
  font-weight: 600;
  box-shadow: 2px 5px 10px rgb(54, 53, 53);
}

.coins-h1-01{
  font-size: 25px;
  font-family: var(--ff4);
}

.coins-h2-01{
  font-size: 18px;
  font-family: var(--ff1);
}

.coins-main-cnt-01 input{
  width: 190px;
  height: 30px;
  margin: 3px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: #000;
  transition: 1s;
}

.coins-main-cnt-01 button{
  width: 120px;
  height: 30px;
  margin-top: 20px;
  background: linear-gradient(90deg, rgb(21, 121, 253), rgb(0, 55, 206));
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 2px 5px 10px rgb(37, 36, 36);
  cursor: pointer;
  
}

.coins-main-cnt-01 input:hover{
  transform: scale(1.2);
}

.data-page-main-cnt-01{
  border: 1px solid;
  height: auto;
  margin: 10px;
  padding: 10px;
  border-top-left-radius: 80px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: linear-gradient(90deg, rgb(21, 121, 253), rgb(0, 55, 206));
  color: white;
  box-shadow: 2px 5px 10px rgb(146, 145, 145);
  cursor: pointer;
  animation-duration: 1.8s;
  animation-name: slide04;
}

@keyframes slide04 {
  from {
      translate: -250vw 0;
      scale: 200% 1;
  }

  to {
      translate: 0 0;
      scale: 100% 1;
  }
}

.data-page-main-cnt-01 span{
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
}

.data-page-main-cnt-01 span strong{
  font-family: var(--ff1);
}
.data-apge-main-cnt-02-sub-01{
  text-align: center;
  border: 1px solid;
  align-content: center;
  margin: 10px;
  padding: 10px;
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
  border-top-left-radius:10px;
  border-bottom-right-radius:10px ;
  background: linear-gradient(90deg, rgb(21, 121, 253), rgb(0, 55, 206));
  color: white;
  animation-duration: 2.5s;
  animation-name: slide05;
}

@keyframes slide05 {
  from {
      translate: 250vw 0;
      scale: 200% 1;
  }

  to {
      translate: 0 0;
      scale: 100% 1;
  }
}


.data-apge-main-cnt-02-sub-01 span{
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
}

.data-apge-main-cnt-02-sub-01 span strong{
  font-family: var(--ff1);
}

.data-apge-main-cnt-02-sub-01 h3{
  font-size: 20px;
  font-family: var(--ff4);
  font-weight: 600;
}

.cart-page-main-cnt-03 h1{
  font-size: 19px;
  font-family: var(--ff1);
}

.cart-page-main-cnt-03 h1 span{
  color: #0ba300;
}

.cart-page-main-cnt-03-icon-01{
  color: #ffae00;
}

.claimdreq-page-h1-01{
  font-size: 20px;
  font-family: var(--ff3);
  color: #292525;
}

.claimdreq-page-h1-01 span{
  color: #ff4800;
  font-size: 20px;
  position: relative;
  right: 10px;
  top: 5px;
}

.claimdreq-page-h1-01-icon-01{
  font-size: 25px;
  margin-left: 10px;
  color: rgb(1, 138, 230);
}

.admin-btn-01{
  width: 60px;
  color: white;
  background-color: #028bc2;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 1px;
  font-family: var(--ff4);
}

.admin-signup-h1-01{
  font-size: 20px;
  font-family: var(--ff1);
}
.admin-span-01{
  font-size: 15px;
  font-family: var(--ff2);
  color: #ff3300;
}

.admin-signup-page-main-cnt-01 input , select{
  width: 200px;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid;
  box-shadow: 2px 2px 10px gray;
  text-align: center;
  color: #000;
}

.admin-signup-page-main-cnt-01 button{
  width: 120px;
  margin-top: 20px;
  height: 30px;
  color: white;
  background: linear-gradient(90deg, orange, orangered);
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.loginadmin-main-cnt-01 input{
  width: 200px;
  height: 30px;
  margin: 2px;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
}

.loginadmin-main-cnt-01 button{
  width: 120px;
  margin-top: 10px;
  height: 30px;
  background: linear-gradient(90deg, orangered, orange);
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
}

.admin-navibar-cnt-01{
  width: 200px;
  height: auto;
  border: 1px solid gray;
  position: absolute;
  left: 10px;
  top: 10px;
  box-shadow: 2px 5px 12px rgb(54, 51, 51);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  align-content: center;
  background: linear-gradient(120deg, rgb(3, 103, 253), rgb(160, 236, 250));
}

.admin-navibar-cnt-01 span{
  cursor: pointer;
  line-height: 30px;
  font-size: 16px;
  font-family: var(--ff2);
  color: white;
}

.admin-navi-icon-01{
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 20px;
  cursor: pointer;
}

.admin-navi-btn-01{
  width: 120px;
  margin-top: 15px;
  height: 30px;
  cursor: pointer;
  font-family: var(--ff1);
  margin-bottom: 20px;
}

.admin-home-h1-01{
  font-size: 20px;
  font-family: var(--ff1);
}

.admin-home-main-cnt-01{
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.admin-home-main-cnt-01-sub-cnt-01{
  width: 400px;
  height: 400px;
  border: 1px solid gray;
  box-shadow: 2px 5px 10px gray;
  background-color: #009ad6;
  border-top-left-radius:25px;
  border-bottom-right-radius:25px ;
  overflow: hidden;
}

@media all and (max-width : 700px){
  .admin-home-main-cnt-01-sub-cnt-01{
    width: 95%;
  }
}

.admin-home-main-cnt-01-sub-cnt-01-span-01{
  font-size: 17px;
  line-height: 30px;
  font-family: var(--ff2);
  color: white;
  font-weight: 600;
}

.admin-home-main-cnt-01-sub-cnt-01-sunb-cnt-01{
  width: 90%;
  height: 340px;
  margin-top: 10px;
  overflow: scroll;
}

.admin-home-main-cnt-01-sub-cnt-01-sunb-cnt-01::-webkit-scrollbar{
  display: none;
}



.admin-home-main-cnt-01-sub-cnt-01-sunb-cnt-01-sub-cnt-01{
  width: 98%;
  height: 50px;
  border: 1px solid rgb(17, 16, 16);
  margin: 2px;
  border-radius: 5px;
  /* display: flex; */
  justify-content: center; /* Aligns children horizontally at the center */
  align-items: center; /* Aligns children vertically at the center */
  text-align: center;
  align-content: center;
}

.admin-home-main-cnt-01-sub-cnt-01-sunb-cnt-01-sub-cnt-01 span{
  font-size: 15px;
  font-family: var(--ff4);
}

.play-main-cnt-02{
  width: 95%;
  height: 80px;
  border: 1px solid gray;
  margin-bottom: 20px;
  background-color: #009ad6;
  border-top-left-radius: 20px;
  border-bottom-right-radius:20px;
  box-shadow: 2px 5px 12px rgb(54, 50, 50);
  align-content: center;
}

.play-main-cnt-02 span{
  font-size: 20px;
  font-family: var(--ff1);
  font-weight: 600;
  color: #ffffff;
}

.play-main-cnt-02-icon-01{
  font-size: 20px;
}

.admin_prize-h1-01{
  font-size: 20px;
  font-family: var(--ff1);
}

.admin_prize-div-cnt-01 input{
  height: 30px;
  width: 200px;
  border-radius: 5px;
  border: 1px solid;
  box-shadow: 2px 5px 10px rgb(82, 78, 78);
  text-align: center;
  font-family: var(--ff1);
}

.admin_prize-div-cnt-01 button{
  width: 120px;
  height: 30px;
  margin-top: 15px;
  cursor: pointer;
}

.admin_prize-div-cnt-01-span-01{
  font-size: 20px;
  font-family: var(--ff1);
}

.admin_prize-div-cnt-01-span-01 strong{
  font-family: var(--ff4);
  color: #0ba300;
  font-size: 25px;
}

.play-main-cnt-02 h3{
  font-size: 15px;
  font-family: var(--ff2);
}

.btnoon-01{
  width: 120px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  font-family: var(--ff1);
  background: linear-gradient(90deg, orange, orangered);
  color: white;
  cursor: pointer;
  box-shadow: 2px 5px 10px rgb(44, 41, 41);
}

.admin_questionadd-h1-01{
  font-size: 20px;
  font-family: var(--ff1);
  font-weight: 800;
}

.admin_questionadd-h1-01 span{
  font-weight: 300;
  color: #fd4800;
}

.admin_questionadd-cnt-01 input, select{
  width: 200px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid;
  margin: 3px;
  box-shadow: 2px 5px 8px rgb(53, 50, 50);
  text-align: center;
  font-family: var(--ff4);
  font-size: 15px;
}

.admin_questionadd-cnt-01 textarea{
  min-width: 200px;
  min-height:30px;
  box-shadow: 2px 5px 8px rgb(82, 76, 76);
  border-radius: 5px;
  border: 1px solid;
  text-align: center;
}

.admin_questionadd-cnt-01 button{
  width: 120px;
  height: 30px;
  margin-top: 20px;
  box-shadow: 2px 5px 8px gray;
  border: 1px solid;
  border-radius: 5px;
  background: linear-gradient(90deg, orange, orangered);
  cursor: pointer;
}

.game_start-span-01{
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
}

.game_start-span-01-strong-01{
  font-family: var(--ff3);
  color: #02b602;
  font-weight: 600;
  font-size: 30px;
}

.game_start-span-01-strong-02{
  font-family: var(--ff3);
  color: #ff5202;
  font-weight: 600;
  font-size: 30px;
}

.game_start-main-cnt-01{

}

.game_start-main-cnt-01-span-01{
  font-size: 25px;
  font-family: var(--ff4);
}
.game_start-main-cnt-01-sub-cnt-01{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
  gap: 20px;
}

.game_start-main-cnt-01-sub-cnt-01 button{
  min-width: 180px;
  min-height: 40px;
  max-height: auto;
  max-width: auto;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 2px 5px 10px gray;
  background: linear-gradient(90deg, rgb(255, 115, 0), orangered);
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  color: white;
}

.game_start-main-cnt-01-sub-cnt-02 button{
  width: 120px;
  margin-top: 25px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  background: linear-gradient(90deg, rgb(60, 138, 255), rgb(1, 90, 173));
  cursor: pointer;
  color: white;
}

.game_start-main-cnt-01-span-01-span-01{
  font-family: var(--ff1);
  font-weight: 300;
}

.admin_cupon-h1-01{
  font-size: 25px;
  font-family: var(--ff1);
  color: #000000;
}

.admin_cupon-main-cnt-01 input, select{
  width: 200px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 2px 5px 10px rgb(49, 46, 46);
  margin: 2px;
  text-align: center;
}

.admin_cupon-main-cnt-01 button{
  width: 120px;
  height: 30px;
  border: 1px solid gray;
  margin-top: 20px;
  border-radius: 5px;
  background: linear-gradient(90deg, rgb(10, 77, 221), rgb(0, 74, 211));
  cursor: pointer;
  color: white;
}

.Claim_cupon-h1-01{
  font-family: var(--ff4);
  font-size: 25px;
  background: linear-gradient(to right, #fd010e,#ffd000); /* Define the gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 5px 10px rgb(65, 58, 58);
}

.Claim_cupon-h1-02{
  font-size: 25px;
  font-family: var(--ff1);
}

.Claim_cupon-h1-02 span{
  color: #02b602;
  font-size: 30px;
}

.claim_cupon-cnt-01{
  width: 300px;
  height: 300px;
  border: 1px solid gray;
  border-top-right-radius: 40px;
  box-shadow: 2px 5px 8px rgb(51, 49, 49);
  background: linear-gradient(90deg, rgb(253, 114, 0), rgb(255, 72, 0));
}

.claim_cupon-cnt-01-span-01{
  font-size: 20px;
  font-family: var(--ff1);
  font-weight: 400;
  line-height: 40px;
}

.claim_cupon-cnt-01-sub-div-cnt-01{
  width: 70%;
  height: auto;
  overflow: hidden;
}

.claim_cupon-cnt-01-sub-div-cnt-01 img{
  width: 100%;
  height: auto;
}

.claim_cupon-cnt-01-span-02{
  font-size: 10px;
  font-family: var(--ff1);
  line-height: 30px;
}

.claim_cupon-cnt-01-span-03{
  font-size: 16px;
  font-family: var(--ff2);
  line-height: 20px;
}

.claim_cupon-cnt-01-span-04{
  font-size: 17px;
  font-family: var(--ff5);
  color: white;
}

.admin_chart-main-cnt-01{
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.admin_chartcnt-01{
  width: 400px;
  height: 300px;
  align-content: center;
}

.admin_speak-main-cnt-01{
  
}

.admin_speak-main-cnt-01-sub-div-01{
  width: 300px;
  height: 50px;
  border: 1px solid;
  border-radius: 20px;
  align-content: center;
  margin: 10px;
}

.admin_speak-main-cnt-01-sub-div-01-icon-01{
  font-size: 20px;
  cursor: pointer;
  color: #28c202;
}

.admin_speak-main-cnt-01-sub-div-01 span{
  font-size: 15px;
  margin: 10px;
  font-family: var(--ff2);
}

.admin_chartcnt-01 h1{
  font-family: var(--ff1);
}

.cupons_h1-01{
  font-size: 30px;
  font-family: var(--ff4);
  font-weight: 400;
}

.About_h1-01{
  font-size: 30px;
  font-family: var(--ff3);
}

.About_h1-01 span{
  font-weight: 200;
  color: #009ad6;
}

.Abou-page-main-cnt-01{
  width: 90%;
  height: auto;
  border: 1px solid;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


.Abou-page-main-cnt-01-text-cnt-01{
  width: 65%;
  height: auto;
  margin: 15px;
  align-content: center;
}

.Abou-page-main-cnt-01-img-cnt-01{
  width: 20%;
  height: auto;
  margin: 15px;
}

.Abou-page-main-cnt-01-img-cnt-01 img{
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 5px;
  background-color: #009ad6;
}

@media all and (max-width : 700px){
  .Abou-page-main-cnt-01{
    width: 90%;
    display: block;
    border-radius: 10px;
    background : linear-gradient(120deg, rgb(187, 185, 185), rgb(252, 252, 252));
    box-shadow: 2px 5px 15px rgb(53, 49, 49);
  }
  .Abou-page-main-cnt-01-img-cnt-01{
    width: 80%;
    border: none;
  }
  .Abou-page-main-cnt-01-text-cnt-01{
    width: 90%; 
    border-radius: 5px;
    align-content: center;
  }
}

.Abou-page-main-cnt-01-text-cnt-01-h1-01{
  text-align: start;
  margin-left: 15px;
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 30px;
}

.Abou-page-main-cnt-01-text-cnt-01-h1-01 strong{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.Abou-page-main-cnt-01-text-cnt-01-h1-01 strong span{
  color: orangered;
}

.Abou-page-main-cnt-01-paragraph-cnt-01{
  font-size: 14px;
  font-family: var(--ff1);
  margin: 5px;
}

.Abou-page-main-cnt-01-paragraph-cnt-01 span{
  color: orangered;
}

.Abou-page-main-cnt-01-text-cnt-01-sub-div-insta-01{
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Abou-page-main-cnt-01-text-cnt-01-sub-div-insta-01 img{
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.Admin_Home_show-main-cnt-01 input{
  width: 200px;
  height: 30px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 2px 5px 10px gray;
  text-align: center;
}

.Admin_Home_show-main-cnt-01 button{
  width: 120px;
  height: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}

.Admin_Home_show-main-cnt-02 button{
  width: 120px;
  height: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
}

.admin_questionview-h1-01{
  font-size: 30px;
  font-family: var(--ff1);
}

.admin_questionview-btn-01{
  width: 120px;
  margin-top: 20px;
  height: 30px;
  box-shadow: 2px 5px 10px gray;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.admin_questions-view-sub-cnt-02{
  display: none;
}

.admin_questions-view-sub-cnt-01 h2{
  font-size: 20px;
  font-family: var(--ff4);
}

.admin_questions-view-sub-cnt-01-btn-01{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.admin_questions-view-sub-cnt-01-btn-01-01{
  margin: 10px;
  min-width: 150px;
  max-width: auto;
  min-height: 30px;
  max-height: auto;
  background-color: orangered;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--ff6);
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.Admin_QuestionView-Answer{
  margin: 10px;
  min-width: 150px;
  max-width: auto;
  min-height: 30px;
  max-height: auto;
  background-color: rgb(29, 175, 0);
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--ff6);
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.admin_questionview-h1-02{
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
}

.admin_questionview-h1-02 strong{
  font-family: var(--ff1);
  font-weight: 600;
  font-size: 20px;
}

.admin_questionview-main-to-btn-01{
  text-align: start;
  margin: 10px;
}

.admin_questionview-main-to-btn-01 button{
  width: 150px;
  height: 30px;
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 5px;
  color: white;
  background: linear-gradient(90deg, rgb(0, 153, 255), rgb(0, 111, 175));
  font-family: var(--ff1);
}

.admin_qstallcheck-main-cnt-01{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
}

.admin_qstallcheck-main-cnt-01-main-cnt-01{
  margin: 10px;
  font-size: 15px;
  font-family: var(--ff1);
  border: 1px solid gray;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  box-shadow: 2px 5px 10px rgb(49, 46, 46);
  align-content: center;
  background-color: rgb(1, 175, 1);
  color: white;
  cursor: pointer;
}

.admin_qstallcheck-main-cnt-01-main-cnt-01:hover{
  width: 100px;
  height: 100px;
}

.admin_qstallcheck-main-cnt-01-main-cnt-02{
  margin: 10px;
  font-size: 15px;
  font-family: var(--ff1);
  border: 1px solid gray;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  box-shadow: 2px 5px 10px rgb(49, 46, 46);
  align-content: center;
  background-color: rgb(255, 81, 0);
  color: white;
}

.admin_prize-div-cnt-02{
  margin-top: 20px;
}

.admin_prize-div-cnt-02 input{
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 2px 5px 10px gray;
  text-align: center;
}

.admin_prize-div-cnt-02 button{
  width: 120px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #009ad6;
  cursor: pointer;
  margin-top: 10px;
}


.admin_prize-div-cnt-02 form{
  width: 300px;
  height: auto;
  padding: 20px;
  border: 1px solid;
  align-content: center;
  border-radius: 4px;
}


.admin_prize-div-cnt-02 strong{
  font-size: 15px;
  line-height: 30px;
  font-family: var(--ff1);
}
.game_start-main-cnt-01-img-cnt-01{
  width: 400px;
  height: auto;
  margin-top: 10px;
}

.game_start-main-cnt-01-img-cnt-01 img{
  width: 100%;
  height: auto;
  object-fit: fill;
  border-radius: 5px;
  box-shadow: 2px 5px 12px rgb(66, 61, 61);
}

@media all and (max-width : 700px){
  .game_start-main-cnt-01-img-cnt-01{
    width: 90%;
  }
}

.admin_addBalance-main-cnt-01 textarea{
  min-width: 250px;
  min-height: 30px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid;
  font-family: var(--ff4);
  font-size: 20px;
}

.admin_addBalance-main-cnt-01 button{
  width: 120px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #028bc2;
  border: 1px solid gray;
  border-radius: 5px;
}

.bottom_page-main-cnt-01{
  width: 90%;
  height: 200px;
  background-color: #009ad6;
}



@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .social_icon,
footer .menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

footer .social_icon li,
footer .menu li {
  list-style: none;
}

footer .social_icon li a {
  font-size: 2em;
  color: #ccc;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}

footer .social_icon li a:hover {
  transform: translateY(-15px);
  color: blue;
}

footer .menu li a {
  font-size: 1.2em;
  color: #ccc;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
}

footer .menu li a:hover {
  opacity: 1;
  color: #fff;
}

/* Wave animation */
footer .wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(https://i.ibb.co/rZt4Nhg/wave.png);
  background-size: 1000px 100px;
}

footer .wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}

footer .wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}

footer .wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 0;
  animation: animateWave 3s linear infinite;
}

footer .wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}

.footer_bootom-copy-prin{
  color: #eee;
  font-size: 2.1em;
  display: flex;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.waves-cnt-01{
  font-size: 4.2em;
  font-family: var(--ff2);
  position: absolute;
  left: 10px;
  top: 0px;
}

.waves-cnt-01 span{
  color: #ff3c00;
}

.pop-up-data-01{
  width: 80vw;
  min-height: 80vh;
  max-height: auto;
  padding: 10px;
  overflow: scroll;
  background-color: #f4f7d7;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgb(75, 73, 73);
  border: 1px solid rgb(102, 102, 102);
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
}

.pop-up-data-01-div-sub-01{
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 0;
}


.pop-up-data-01-div-sub-01 button{
  width: 120px;
  height: 40px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
}

.waves-cnt-02{
  width: 95%;
  height: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}



.waves-cnt-02-sub-01{
  width: 400px;
  height: 200px;
}



@media all and (max-width : 700px){
  .waves-cnt-02{
    width: 95%;
  }
}

.waves-cnt-02-sub-01-h1-01{
  font-family: var(--ff4);
  font-size: 25px;
  color: rgb(243, 249, 255);
}

.waves-cnt-02-sub-01{
  
}

.waves-cnt-02-sub-01 span{
  font-size: 16px;
  font-family: var(--ff1);
  color: rgb(255, 255, 255);
  line-height: 35px;
  font-weight: 600;
  cursor: pointer;
}

.waves-cnt-02-sub-01-img-cnt-01{
  width: 30px;
  height: 30px;
}

.waves-cnt-02-sub-01-img-cnt-01 img{
  width: 100%;
  height: 100%;
  object-fit: fill;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.waves-cnt-02-sub-01-sub-01-cnt{
  display: flex;
  justify-content: center;
  gap: 10px;
}

.Home_page_about-cnt-01{
  width: 95%;
  min-height: 100px;
  max-height: auto;
  margin-top: 25px;
  padding: 5px;
}

.Home_page_about-cnt-01-h1-01{
  font-family: var(--ff3);
  font-size: 5.3em;
  text-align: start;
  color: #3e3f3e;
}

.Home_page_about-cnt-01-h1-01 span{
  font-family: var(--ff7);
  font-weight: 300;
  color: #00a2ff;
}

.Home_page_about-cnt-01-sub-cnt-01{
  width: 90%;
  min-height: 250px;
  max-height: auto;
  border: 1px solid rgb(182, 182, 182);
  position: relative;
  box-shadow: 5px 8px 12px rgba(34, 33, 33, 0.559);
}

.Home_page_about-cnt-01-sub-cnt-01-sub-01{
  width: 250px;
  height: 250px;
  border: 1px solid gray;
  position: absolute;
  top: -20px;
  left: -15px;
  border-radius: 2px;
  box-shadow: 5px 8px 12px rgba(34, 33, 33, 0.559);
  overflow: hidden;
}

.Home_page_about-cnt-01-sub-cnt-01-sub-01 img{
  width: 100%;
  height: auto;
  object-position: start;
  position: relative;
}

.Home_page_about-cnt-01-sub-cnt-01-sub-02{
  width: 50%;
  margin-top: 20px;
}

.Home_page_about-cnt-01-sub-cnt-01-sub-02 h1{
  font-family: var(--ff4);
  font-size: 3.8em;
  color: #282525;
  font-weight: 200;
}

.Home_page_about-cnt-01-sub-cnt-01-sub-02 h1 strong{
  font-weight: 1000;
}

.Home_page_about-cnt-01-sub-cnt-01-sub-02 h1 span{
  color: #ff3300;
}

@media all and (max-width : 700px){
  .Home_page_about-cnt-01-sub-cnt-01-sub-02{
    margin-top: 250px;
    transition: 2s;
    width: 99%;
  }
}

.play-main-cnt-03{
  width: 90%;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.play-main-cnt-03-sub-div-01{
  width: 250px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 5px;
  background: linear-gradient(50deg, rgb(0, 79, 226), rgb(0, 123, 255));
  cursor: pointer;
  align-content: center;
}

.play-main-cnt-03-sub-div-02{
  width: 250px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 5px;
  background: linear-gradient(50deg, rgb(0, 226, 11), rgb(9, 170, 1));
  cursor: pointer;
  align-content: center;
}


@media all and (max-width : 600px){
  .play-main-cnt-03-sub-div-01{
    width: 250px;
    height: 50px;
    align-content: center;
  }
}

.play-h1-may-be-01{
  font-family: var(--ff1);
  font-size: 20px;
  color: #2f2e2e;
}

.play-main-cnt-03-sub-div-01 h1{
  font-family: var(--ff2);
  color: rgb(255, 255, 255);
}

.play-main-cnt-03-sub-div-02 h1{
  font-family: var(--ff2);
  color: rgb(255, 255, 255);
}

.selected {
  background-color: #4caf50; /* Green or any color for selected languages */
  color: white;
  align-content: center;
}

.play-main-btn-01 button{
  width: 120px;
  height: 35px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: rgb(255, 119, 0);
}

.play-main-slected-topics-sho-01 h2{
  font-family: var(--ff1);
  font-size: 2.3em;
  color: #292929;
  text-align: start;
  margin-left : 20px;
}

.play-main-slected-topics-sho-01-sub-01{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 15px;
  width: 100%;
  height: auto;
}


.play-main-slected-topics-sho-01-sub-cnt-01{
  min-width: 120px;
  max-width: auto;
  height: 30px;
  border: 1px solid black;
  border-radius: 8px;
  align-content: center;
  font-size: 2em;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(250, 70, 4), rgb(255, 119, 0));
  color: white;
  font-family: var(--ff6);
  margin: 5px;
  padding: 3px;
}


.play-main-slected-topics-sho-01-sub-cnt-02{
  min-width: 120px;
  max-width: auto;
  height: 30px;
  border: 1px solid black;
  border-radius: 8px;
  align-content: center;
  font-size: 2em;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(26, 177, 3), rgb(0, 255, 0));
  color: white;
  font-family: var(--ff6);
  margin: 5px;
  padding: 3px;
}

.kick-corect-answers-01{
  background-color: #109a06;
}

.pop-up-data-01-sub-cnt-01-main{
  overflow: scroll;
}

.pop-up-data-01-sub-cnt-01-main-sub-cnt-01-img-cnt-01{
  width: 300px;
  height: 200px;
  object-fit: cover;
  border: 1px solid;
}

.pop-up-data-01-sub-cnt-01-main-sub-cnt-01-img-cnt-01 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pop-up-data-01-sub-cnt-01-main-sub-cnt-01-img-cnt-02{
  display: none;
}

@media all and (max-width : 600px){
  .pop-up-data-01-sub-cnt-01-main-sub-cnt-01-img-cnt-01{
    width: 90%;
    height: auto;
  }
}

.easy_type_01{
  background-color: #15f615;
  color: white;
}

.easy_type_02{
  background-color: #03d318;
  color: white;
}

.easy_type_03{
  background-color: #ffe601;
  color: white;
}

.easy_type_04{
  background-color: #ff7301;
  color: white;
}

.easy_type_05{
  background-color: #ff4101;
  color: white;
}

.admin_questionadd-h1-may-be-01{
  font-family: var(--ff1);
}

.admin_questionadd-maybe-subb-cnt-01{
  width: 90%;
  height: auto;
  padding: 10px;
}

.admin_questionadd-maybe-subb-cnt-01-btn-01{
  width: 140px;
  height: 35px;
  color: white;
  background-color: #03d318;
  border: 1px solid gray;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  margin: 5px;
}


.admin_questionadd-maybe-subb-cnt-01-btn-02{
  min-width: 140px;
  max-width: auto;
  height: 35px;
  color: white;
  background-color: #ff5100;
  border: 1px solid gray;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  font-family: var(--ff6);
}

.admin_questionadd-maybe-subb-cnt-01-btn-04{
  min-width: 140px;
  max-width: auto;
  height: 35px;
  color: white;
  background-color: #000000;
  border: 1px solid gray;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  font-family: var(--ff6);
}



.admin_questionadd-maybe-subb-cnt-01-input-01{
  width: 180px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid gray;
  text-align: center;
  font-family: var(--ff2);
  color: #000;
}


.terms_page-main-cnt-01 h1{
  font-size: 35px;
  font-family: var(--ff4);
}

.terms_page-main-cnt-01 h1 span{
  font-size: 35px;
  font-family: var(--ff1);
  font-weight: 300;
}

.terms_page-main-cnt-01 h2{
  font-size:25px;
  font-family: var(--ff1);
  text-align: start;
}


.terms_page-main-cnt-01_sub-01{
  width: 90%;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px gray;
  text-align: start;
  background-color: #f0f0f0;
}

.terms_page-main-cnt-01_sub-01 p{
  font-size: 15px;
  font-family:'Times New Roman', Times, serif;
}


.stawro{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #302c2c;
}

.stawro span{
  color: rgb(255, 62, 4);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.privacy_page-h1-01{
  font-size: 35px;
  font-family: var(--ff4);
}

.privacy_page-main-cnt-01{
  width: 90%;
}

.privacy_page-main-cnt-01 p{
 font-size: 15px;
 font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}

.privacy_page-main-cnt-02{
  width: 90%;
  height: auto;
  padding: 10px;
}

.privacy_page-main-cnt-02 h2{
  font-size: 25px;
  font-family: var(--ff1);
  text-align: start;
}

.privacy_page-main-cnt-02 p{
  text-align: start;
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
}

.privacy_page-main-cnt-02 ul{
  text-align: start;
  font-size: 14px;
  font-family: var(--ff4);
}


.privacy_page-h1-main-cnt-01{
  width: 95%;
}

.shipping_page-h1-01{
  font-family: 'Times New Roman', Times, serif;
  font-size: 3.5em;
}

.shipping_main-sub-cnt-02{
  width: 90%;
  height: auto;
  padding: 10px;
}

.shipping_main-sub-cnt-02 h2{
  text-align: start;
  font-size: 2.8em;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: #0c59be;
}

.shipping_main-sub-cnt-02 h3{
  text-align: start;
  padding: 10px;
  font-family: var(--ff5);
  font-size: 2em;
  font-weight: 600;
}

.shipping_main-sub-cnt-02 li{
  text-align: start;
  font-size: 14px;
  font-family: monospace;
}

.shipping_main-sub-cnt-02 p{
  text-align: start;
  padding: 10px;
  font-size: 14px;
  font-family: var(--ff2);
  font-weight: 600;
  color: #464646;
}


@media all and (max-width : 700px){
  .shipping_main-sub-cnt-02 h2{
    font-size: 3.5em;
  }
  .shipping_main-sub-cnt-02 h3{
    font-size: 2.8em;
  }
}

.waves-cnt-03 span{
  cursor: pointer;
  margin: 10px;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  color: white;
  font-weight: 600;
}

.waves-cnt-02-sub-01-h2-01{
  color: white;
}

.waves-cnt-02-sub-01-h2-01 span{
  color: #353230;
  font-size: 29px;
}

.waves-cnt-02-sub-01-h2-01 strong{
  color: #ffffff;
  font-family: var(--ff5);
}

.cart-page-main-cnt-01-sub-cnt-01-h2-01{
  font-size: 3em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #413e3e;
}

.data_page-h2-01{
  font-size: 30px;
  font-family: var(--ff3);
  font-weight: 600;
}

.data_page-h2-01 span{
  color: #009ad6;
  font-weight: 300;
}

.data_page-profile-cnt-01{
  width: 150px;
  height: 150px;
  border: 1px solid rgb(199, 199, 199);
  border-radius: 50%;
  box-shadow: 2px 2px 10px gray;
  overflow: hidden;
}

.data_page-profile-cnt-01 img{
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

@media all and (max-width : 600px){
  .data_page-profile-cnt-01{
    width: 120px;
    height: 120px;
  }
}

.data_page-h2-02{
  font-size: 2.5em;
  font-family: var(--ff1);
}

.data_page-h2-02 span{
  font-family: var(--ff3);
  font-size: 1.4em;
  color: #0076d6;
}

.users_login-page-h1-01{
  font-family: var(--ff1);
  font-size: 3em;
}

.user_admin-input-field-01{
  width: 180px;
  height: 35px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  margin: 5px;
  border: 1px solid;
  border-radius: 5px;
  appearance: none;
  -moz-appearance: textfield; /* For Firefox */
  -webkit-appearance: none;
  
}

.user_admin-button-login{
  width: 120px;
  height: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid black;
  color: white;
  border-radius: 5px;
  background: linear-gradient(90deg, rgb(0, 98, 255), rgb(0, 191, 255));
}

.add_admins_page-h1-01{
  font-family: var(--ff1);
  font-size: 3em;
  color: #1b1919;
}

.table-01{
  width: 90%;
  height: auto;
  border: 1px solid;
  font-size: 15px;
}

td, tr, th{
  border: 1px solid black;
  text-align: center;
  font-family: var(--ff3);
  font-size: 20px;
}

.no-arrows {
  /* For most modern browsers */
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  
  /* To handle padding issues */
  margin: 0;
}

/* For Internet Explorer (optional) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user_admin_home_page-cnt-01{
  
}

.user_admin_home_page-cnt-01-h1-01{
  font-family: var(--ff1);
  font-size: 25px;
  font-weight: 300;
}

.user_admin_home_page-cnt-01-h1-01 span{
  color: #0076d6;
  font-weight: 1000;
}

.user_admin_home_page-cnt-01-sub-cnt-01{
  width: 95%;
  height: auto;
  padding: 10px;
  gap: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.user_admin_home_page-cnt-01-sub-cnt-01-sub-cnt-01{
  width: 150px;
  height: 150px;
  border: 1px solid;
  cursor: pointer;
  border: 1px solid gray;
  box-shadow: 2px 5px 10px gray;
  border-radius: 10px;
  align-content: center;
  background: linear-gradient(130deg, rgb(0, 170, 255), rgb(27, 99, 161));
}

.icon--01{
  font-size: 35px;
  color: white;
}

.user_admin_home_page-cnt-01-sub-cnt-01-sub-cnt-01 span{
  font-size: 15px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #ffffff;
}

.main-h1-01{
  font-size: 25px;
  font-family: var(--ff1);
}


.button-01{
  width: 100px;
  height: 30px;
  border: 1px solid gray;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(0, 99, 227), rgb(111, 194, 250));
}

.users_admin_page-main-cnt-01{
  width: 90%;
  height: auto;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 2px 4px 10px gray;
  background: linear-gradient(90deg, rgb(41, 157, 246), rgb(39, 126, 193));
}

.users_admin_page-main-cnt-01 h1{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 23px;
  color: white;
}

.users_admin_page-main-cnt-01 h1 strong{
  color: #57fc4e;
  font-family: var(--ff2);
}

.users_admin_page-main-cnt-01 h2{
  font-size: 14px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
}

.users_admin_page-main-cnt-01 h2 strong{
  color: #ffd000;
}

.sel_question-span-01{
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
}

.sel_question-main-cnt-01{
  width: 90%;
  height: auto;
}

.sel_question-main-cnt-01-sub-01{
  width: 99%;
  height: auto;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  margin: 1px;
  cursor: pointer;
  /* background: linear-gradient(90deg, rgb(255, 115, 0), rgb(226, 83, 6)); */
  color: white;
}

.sel_question-main-cnt-01-sub-01-h2-01{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.sel_question-main-cnt-01-sub-01-show-more{
  width: 95%;
  height: auto;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  box-shadow: 2px 4px 10px gray;
  transform: scale(1.1);
  transition: 0.5s;
  background: linear-gradient(90deg, rgb(0, 153, 255), rgb(0, 42, 255)) ;
  color: white;
}

.sel_question-main-cnt-01-sub-01-h2-01-span-01{
  font-size: 14px;
  font-family: 'Times New Roman', Times, serif;
}

.user_admin-navibar-01{
  width: 100px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: auto;
  border: 1px solid;
  padding: 10px;
  box-shadow: 2px 2px 10px gray;
  border-radius: 10px;
  background: linear-gradient(60deg, rgb(2, 127, 253), rgb(0, 102, 255));
  color: white;
  cursor: pointer;
}

.user_admin-navibar-01-sub-cnt-01-icon-01{
  font-size: 20px;
}

.user_admin-navibar-01-sub-cnt-01-sub-01-sub-01{
  width: 90%;
  height: auto;
  font-size: 20px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.user_admin-navibar-01-sub-cnt-01-sub-01-sub-01:hover{
  color: #d63900;
  transform: scale(1);
  transition: 0.5s;
}

.btn-01{
  width: 90%;
  height: 35px;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: 1px solid ;
  border-radius: 10px;
  margin-top: 10px;
}

.users_admin_page-main-cnt-02{
  width: 200px;
  height: 100px;
  border: 1px solid;
  border-radius: 10px;
  background-color: #0076d6;
  align-content: center;
}

.users_admin_page-main-cnt-02 h2{
  color: white;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif ;
}

.users_admin_page-main-cnt-02 h2 span{
  color: rgb(253, 252, 252);
  font-size: 20px;
}

