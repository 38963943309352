.loading_body{
    width: 100%;
    height: 90vh;
    display: block;
     justify-content: center;    
    align-content: center;
}

.loading_page-h1-01{
    font-size: 5vw;
    color: rgb(90, 88, 88);
}

@media all and (max-width : 700px){
    .loading_page-h1-01{
        font-size: 9vw;
    }
}

.loading_page-h1-01 strong{
    color: orangered;
}

.span-1{
    animation-duration: 0.5s;
    animation-name: slide1;
}

.span-2{
    animation-duration: 0.7s;
    animation-name: slide1;   
}

.span-3{
    animation-duration: 0.8s;
    animation-name: slide1;   
}

.span-4{
    animation-duration: 1.3s;
    animation-name: slide1;   
}

.span-5{
    animation-duration: 0.8s;
    animation-name: slide1;   
}

.span-6{
    animation-duration: 0.9s;
    animation-name: slide2;   
}




@keyframes slide1 {
    from {
        translate: 0 -250vw;
        scale: 200% 2;
    }

    to {
        translate: 0 0;
        scale: 100% 1;
    }
}


@keyframes slide2 {
    from {
        translate:100vh 0;
        scale: 200% 2;
    }

    to {
        translate: 0 0;
        scale: 100% 1;
    }
}


.loading_page-logo_cnt-01{
    display: flex;
    align-content: center;
    justify-content: center;
}

.loading_page_img-cnt-01{
    width: 15%;
    height: auto;
    object-fit: fill;
    animation-duration: 0.5s;
    animation-name: slide3;  
}

@keyframes slide3 {
    from {
        translate:-200vw 0;
        scale: 200% 2;
    }

    to {
        translate: 0 0;
        scale: 100% 1;
    }
}

.loading_page_img-cnt-01 img{
    width: 100%;
    height: auto;
    object-fit: fill;
}


@media all and (max-width: 700px){
    .loading_page_img-cnt-01{
        width: 30%;
    }
}


